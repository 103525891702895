class RealTimeService {


    /**
     *
     * @param {String} url - to where the websocket will connect
     * @param {String | String[]} protocols - Protocol of the websocket
     * @param {Map} events shape-> key:eventName value:callBack (func args : wsId, event) events to add for this websocket
     * @returns {String|boolean} id of the websocket (used for other actions) if successful or false is there is an error 
     */
    connect(url, protocols, events) {
        return this.realTime.connect(url, protocols, events);
    };

    /**
     *
     * @param {string} wsId - id of the websocket to close
     * @return {string|boolean} returns true if no error occurs or returns a string with the error
     */
    close(wsId) {
        return this.realTime.close(wsId);
    };

    /**
     *
     * @param {string} wsId - id of the websocket to send the message
     * @param {object|string} message - object (will be parsed) or string to send
     * @return {string|boolean} returns true if no error occurs or returns a string with the error
     */
    sendMessage(wsId, message) {
        return this.realTime.sendMessage(wsId, message);
    };

    /**
     *
     * @param {string} wsId - the id of the websocket to add the callback
     * @param {string} eventName - One of {onopen,onmessage,onclose,onerror}
     * @param {function} callback - callback function to be called en the websocket event is triggered (func args : wsId, event)
     */
    addEvent(wsId, eventName, callback) {
        return this.realTime.addEvent(wsId, eventName, callback);
    };

    /**
     *
     * @param {string} wsId - the id of the websocket to add the callback
     * @param {string} eventName - One of {onopen,onmessage,onclose,onerror}
     */
    removeEvent(wsId, eventName) {
        this.realTime.addEvent(wsId, eventName);
    };

    /**
     *
     * @param {*} realTime
     */
    setRealTimeProvider(realTime) {
        this.realTime = realTime;
    }

    /**
     *
     * @param {*} store
     */
    setStore(store) {
        this.realTime.setStore(store);
    }
}

export default RealTimeService;